/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id2: null,
}

export const Row2Slice = createSlice({
  name: 'row2',
  initialState: { id2: initialState },
  reducers: {
    setRowId: (state, action) => {
      state.id = action.payload
    },
  },
})

export const { setRowId } = Row2Slice.actions

export default Row2Slice.reducer
