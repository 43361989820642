/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Employee_Name: false,
  img: false,
  namePage: true,
  Name: null,
};

export const HeaderDataSlice = createSlice({
  name: "visibleHeaderData",
  initialState: { visible: initialState },
  reducers: {
    setHeaderData: (state, { payload }) => {
      state.visible = { ...payload };
    },
  },
});

export const { setHeaderData } = HeaderDataSlice.actions;
export default HeaderDataSlice.reducer;
