/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = [
]

export const multiRowSelectionSlice = createSlice({
  name: 'row',
  initialState: { row: initialState },
  reducers: {
    setRowSelection: (state, action) => {
      state.row = action.payload
    },
    cleanUpRowSelection: (state) => {
      state.row = initialState
    },
  },
})

export const { setRowSelection, cleanUpRowSelection } = multiRowSelectionSlice.actions

export default multiRowSelectionSlice.reducer
