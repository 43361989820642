/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  counter: 0,
};

export const StepperSlice = createSlice({
  name: "counter",
  initialState: { counter: initialState },
  reducers: {
    setCount: (state, action) => {
      state.counter = action.payload;
    },
  },
});

export const { setCount } = StepperSlice.actions;

export default StepperSlice.reducer;
