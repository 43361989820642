import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import leadsSlice from "../features/leads/leadSlice";
import showModalSlice from "../redux/reducer/showModalSlice";
import counterReducer from "../redux/reducer/StepperSlice";
import HeaderReducer from "../redux/reducer/Header/HeaderSlice";

////
// import put_postReducer from './redux/reducer/EditableTable/PutPostSlice'
import put_postReducer from "../redux/reducer/EditableTable/PutPostSlice";
import editTableReducer from "../redux/reducer/EditableTable/EditOnClickSlice";
import rowSelectionReducer from "../redux/reducer/EditableTable/RowSelectionSlice";
import Row2Reducer from "../redux/reducer/EditableTable/Row2Slice";
import previewRowReducer from "../redux/reducer/EditableTable/PreivewRow";
import multiRowSelectionSlice from "../redux/reducer/EditableTable/mutliRowSelection";
const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  stepper: counterReducer,
  showModal: showModalSlice,
  HeaderReducer: HeaderReducer,
  //////
  table: put_postReducer,
  editTable: editTableReducer,
  row: rowSelectionReducer,
  row2: Row2Reducer,
  previewRow: previewRowReducer,
  multiRowSelection: multiRowSelectionSlice,
};

export default configureStore({
  reducer: combinedReducer,
});
