/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  isDeleted: false,
};

export const showModalSlice = createSlice({
  name: "visible",
  initialState: { visible: initialState },
  reducers: {
    showModal: (state, { payload }) => {
      state.visible = { ...payload };
    },
    cleanupShowModal: (state) => {
      state.visible = initialState;
    },
  },
});

export const { showModal, cleanupShowModal } = showModalSlice.actions;
export default showModalSlice.reducer;
